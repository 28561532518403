const PlanData = [
  {
    id: "planBanner1",
    btnid: "planSelection1",
    name: "Clearvikalp Blood Checkup",
    title: "Heart, Diabetes, Liver, Kidney, Infection, screening",
    amount: {
      withDiscount: "₹ 1599",
      withoutDiscount: "4569",
      discount: "65% off",
    },
    noOfTest: "50",
    test: [
      "CBC",
      "Blood Group",
      "Urine Routine",
      "Hba1c",
      "RBS",
      "Serum Creatinine",
      "Bilirubin",
      "SGOT",
      "SGPT",
      "Serum Electrolytes",
      "Lipid Profile",
    ],
  },
  {
    id: "planBanner2",
    btnid: "planSelection2",
    name: "Customized Blood Checkup",
    title:
      "Heart, Diabetes, Liver, Kidney, Infection screening, Vitamin screening, Thyroid, Prostate screening, HIV screening",
    amount: {
      withDiscount: "Starting at ₹ 1599",
      withoutDiscount: "",
      discount: "86% off",
    },
    noOfTest: "75",
    test: [
      "CBC",
      "ESR",
      "Blood Group",
      "Stool Routine",
      "Urine Routine",
      "Vit- B12",
      "Vit-D3",
      "Lipid Profile",
      "FBS",
      "PPBS",
      "Serum Creatinine",
      "Serum Electrolytes",
      "Serum Uric Acid",
      "BUN",
      "Thyroid",
      "Hba1c",
      "Hbsag",
      "HIV I & II",
      "Prostate Surface Antigen",
      "Albumin",
      "Alkaline Phosphatase",
      "Bilirubin",
      "GGTP",
      "Globulin",
      "SGOT",
      "SGPT",
      "Total Protein",
    ],
  },
  // {
  //   id: "planBanner3",
  //   btnid: "planSelection3",
  //   name: "Clearvikalp Wellness Pro",
  //   title:
  //     "Heart, Diabetes, Liver, Kidney, Infection screening, Vitamin screening, Thyroid, Prostate screening, HIV screening",
  //   amount: {
  //     withDiscount: "3499",
  //     withoutDiscount: "10000",
  //     discount: "86% off",
  //   },
  //   noOfTest: "75",
  //   test: [
  //     "CBC",
  //     "ESR",
  //     "Blood Group",
  //     "Stool Routine",
  //     "Urine Routine",
  //     "Vit- B12",
  //     "Vit-D3",
  //     "Lipid Profile",
  //     "FBS",
  //     "PPBS",
  //     "Serum Creatinine",
  //     "Serum Electrolytes",
  //     "Serum Uric Acid",
  //     "BUN",
  //     "Thyroid",
  //     "Hba1c",
  //     "Hbsag",
  //     "HIV I & II",
  //     "Prostate Surface Antigen",
  //     "Albumin",
  //     "Alkaline Phosphatase",
  //     "Bilirubin",
  //     "GGTP",
  //     "Globulin",
  //     "SGOT",
  //     "SGPT",
  //     "Total Protein",
  //   ],
  // },
];

export default PlanData;
